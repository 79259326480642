import React from 'react';
import { Navigate } from 'react-router-dom';

const VerifiedRoute = ({ children, user }) => {
    if (!user) {
        return <Navigate to="/login" />
    }
    if (user.verified === false) {
        return <Navigate to="/upload-documents" />;
    }

    return children;
};

export default VerifiedRoute;
