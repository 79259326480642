const rolesConfig = {
    3: [
        { path: '/dashboard', name: 'Dashboard' },
        { path: '/users', name: 'Usuarios' },
    ],
    2: [
        { path: '/creditos', name: 'Credito' },
    ],
    1: [
        { path: '/upload-documents', name: 'Subida de documentos' }
    ]

};

export default rolesConfig;
