import React from 'react'
import { Container } from 'react-bootstrap'

export default function PrivacyPolicy() {
  return (
    <Container className="my-5">
      <h1>Política de Privacidad según la Legislación Ecuatoriana</h1>
      <p className="mt-3">Esta página resume las leyes de protección de datos personales de Ecuador y cómo nuestra empresa las cumple.</p>
    </Container>
  )
}
