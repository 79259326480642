import { createAsyncThunk } from '@reduxjs/toolkit'
import { TOKEN } from '../../config/constants'
import { API_PATH_PREFIX, instance } from '../../config/fetcher'
import { handleUseCaseError } from '../../utils/errorHandler.js'
import { setSecuredItem } from '../../utils/secureStorage'
import { objectToAuthUser } from '../representations/mappers/authUserMapper'

export function loginActions() {
    const login = createAsyncThunk('@auth/login', async (credentials, thunkAPI) => {
        const { rejectWithValue } = thunkAPI
        const { email, password } = credentials
        
        try {
            const authData = await instance.post(`${API_PATH_PREFIX}/users/login`, { email, password }).then((response) => response.data)
            const authUser = objectToAuthUser(authData)
            return authUser
        } catch (error) {
            return rejectWithValue(handleUseCaseError(error.response.data.error))
        }
    })

    const handlePending = (state) => {
        state.isLoading = true
        state.error = null
    }

    const handleFulfilled = (state, action) => {
        state.isLoading = false
        state.authUser = action.payload
        state.hasToken = true
        setSecuredItem(TOKEN, action.payload.token.accessToken)
    }

    const handleRejected = (state, action) => {
        state.isLoading = false
        state.error = action.payload
    }

    return { login, handlePending, handleFulfilled, handleRejected }

}