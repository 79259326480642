import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { initializeAuth } from '../../auth/authSlice/authSlice';

const PrivateRoute = ({ children }) => {
    const dispatch = useDispatch();
    const isAuthenticated = useSelector((state) => state.auth.hasToken);

    useEffect(() => {
        dispatch(initializeAuth());
    }, [dispatch]);

    return isAuthenticated ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
