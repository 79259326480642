export const setSecuredItem = (key, value) => {
    try {
        localStorage.setItem(key, value);
    } catch (error) {
        console.error('🚀 ~ setSecuredItem ~ error:', error);
    }
};

export const getSecuredItem = (key) => {
    try {
        const value = localStorage.getItem(key);
        if (value !== null) {
            return value;
        }
    } catch (error) {
        console.error('🚀 ~ getSecuredItem ~ error:', error);
    }
};

export const deleteSecureItem = (key) => {
    try {
        localStorage.removeItem(key);
    } catch (error) {
        console.error('🚀 ~ deleteSecureItem ~ error:', error);
    }
};
