const formStyles = {
  form: {
    maxWidth: '500px',
    margin: '0 auto',
  },
  alertError: {
    marginTop: '30px',
  }
};

export default formStyles;
