import React, { useState } from "react";
import { Button, Card, Carousel, Col, Container, Row } from "react-bootstrap";
import { AccessModal, AnalysisModal, SecurityModal } from "./components";
import "./style.css";

export default function HomePage() {
  const [showModalSeguridad, setShowModalSeguridad] = useState(false);
  const [showModalAnalisis, setShowModalAnalisis] = useState(false);
  const [showModalAcceso, setShowModalAcceso] = useState(false);

  const handleModalToggle = (setter) => () => setter(prevState => !prevState);

  return (
    <div className="home-page">
      <Container style={{ maxWidth: "800px", marginTop: '1%' }}>
        <Carousel>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="https://img.freepik.com/vector-gratis/concepto-mercado-valores-degradado_23-2149166910.jpg"
              alt="Cuentas Demo y Reales"
            />
            <Carousel.Caption>
              <h3>Cuentas Demo y Reales</h3>
              <p>
                Experimenta con cuentas demo o comienza a operar con cuentas reales.
              </p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="https://c0.wallpaperflare.com/preview/373/206/569/black-and-white-chart-cost-industry.jpg"
              alt="Soluciones Innovadoras"
            />
            <Carousel.Caption>
              <h3>Soluciones Innovadoras</h3>
              <p>
                Utiliza nuestra plataforma intuitiva para optimizar tus operaciones con cuentas demo y reales.
              </p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </Container>

      <Container className="my-5">
        <Row>
          <Col md={4}>
            <Card>
              <Card.Img
                variant="top"
                src="https://images.pexels.com/photos/60504/security-protection-anti-virus-software-60504.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt="Seguridad de Datos"
              />
              <Card.Body>
                <Card.Title>Seguridad de Datos</Card.Title>
                <Card.Text>
                  Protegemos tu información con la más alta seguridad en el manejo de tus cuentas.
                </Card.Text>
                <Button
                  variant="primary"
                  style={{ borderColor: "white" }}
                  onClick={handleModalToggle(setShowModalSeguridad)}
                >
                  Saber más
                </Button>
              </Card.Body>
              <SecurityModal
                showModal={showModalSeguridad}
                handleCloseModal={handleModalToggle(setShowModalSeguridad)}
              />
            </Card>
          </Col>
          <Col md={4}>
            <Card>
              <Card.Img
                variant="top"
                src="https://static.vecteezy.com/system/resources/previews/020/467/217/non_2x/stock-market-graph-trading-analysis-investment-financial-stock-exchange-financial-or-forex-graph-stock-chart-graph-business-crisis-crash-loss-and-grow-up-gain-and-profits-win-up-trend-growth-money-free-photo.jpg"
                alt="Análisis Avanzado"
              />
              <Card.Body>
                <Card.Title>Análisis Avanzado</Card.Title>
                <Card.Text>
                  Herramientas de análisis para tomar mejores decisiones en tus operaciones.
                </Card.Text>
                <Button
                  variant="primary"
                  style={{ borderColor: "white" }}
                  onClick={handleModalToggle(setShowModalAnalisis)}
                >
                  Descubre
                </Button>
              </Card.Body>
              <AnalysisModal
                showModal={showModalAnalisis}
                handleCloseModal={handleModalToggle(setShowModalAnalisis)}
              />
            </Card>
          </Col>
          <Col md={4}>
            <Card>
              <Card.Img
                variant="top"
                src="https://images.pexels.com/photos/3760067/pexels-photo-3760067.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt="Registro de Brokers"
              />
              <Card.Body>
                <Card.Title>Registro de Brokers</Card.Title>
                <Card.Text>
                  Regístrate y accede a cuentas demo y reales para mejorar tus habilidades de trading.
                </Card.Text>
                <Button
                  variant="primary"
                  style={{ borderColor: "white" }}
                  onClick={handleModalToggle(setShowModalAcceso)}
                >
                  Explorar
                </Button>
              </Card.Body>
              <AccessModal
                showModal={showModalAcceso}
                handleCloseModal={handleModalToggle(setShowModalAcceso)}
              />
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
