import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faCertificate, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col } from 'react-bootstrap'

function Footer() {
  return (
    <footer className="bg-white text-dark text-center p-4">
      <Container>
        <Row className="my-2">
          <Col>
            <small> Todos los derechos reservados &copy; {new Date().getFullYear()} Joel Arguello - Euro Forex Broker</small>
          </Col>
        </Row>
        <Row className="my-3">
          <Col>
            <a href="mailto:joel.darguello@gmail.com" className="text-dark mx-2">
              <FontAwesomeIcon icon={faEnvelope} size="lg" />
            </a>
            <a href="https://wa.me/593998500498" className="text-dark mx-2">
              <FontAwesomeIcon icon={faWhatsapp} size="lg" />
            </a>
            <FontAwesomeIcon icon={faCertificate} className="text-dark mx-2" size="lg" title="Certificado de Seguridad" />
          </Col>
        </Row>
        <Row className="my-3">
          <Col>
            <a href="/privacypolicy" className="text-dark mx-2">
              Política de Privacidad
            </a>
            <a href="/terms" className="text-dark mx-2">
              Términos y Condiciones
            </a>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
