import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { initializeAuth } from './auth/authSlice/authSlice';
import Login from './auth/pages/login';
import Register from './auth/pages/register';
import Contact from './common/pages/contact';
import Dashboard from './dashboard';
import Error404 from './error/error404';
import HomePage from './home/';
import PrivateRoute from './hooks/routes/privateRoute';
import VerifiedRoute from './hooks/routes/verifiedRutes';
import Layout from './layout';
import PrivacyPolicy from './legal/privacyPolicy';
import UploadDocuments from './userDocument/screens/Home';
import UserList from './users/screens/usersTable';

export default function AppRoutes() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(initializeAuth());
    }, [dispatch]);

    const { authUser } = useSelector((state) => state.auth);

    return (
        <Routes>
            <Route
                path="/"
                element={
                    <Layout>
                        <HomePage />
                    </Layout>
                }
            />
            <Route
                path="/login"
                element={
                    <Layout>
                        <Login />
                    </Layout>
                }
            />
            <Route
                path="/register"
                element={
                    <Layout>
                        <Register />
                    </Layout>
                }
            />
            <Route
                path="/contact"
                element={
                    <Layout>
                        <Contact />
                    </Layout>
                }
            />
            <Route
                path="/dashboard"
                element={
                    <PrivateRoute>
                        <VerifiedRoute user={authUser}>
                            <Layout>
                                <Dashboard />
                            </Layout>
                        </VerifiedRoute>
                    </PrivateRoute>
                }
            />
            <Route
                path="/users"
                element={
                    <PrivateRoute>
                        {/* <VerifiedRoute user={authUser}> */}
                        <Layout>
                            <UserList />
                        </Layout>
                        {/* </VerifiedRoute> */}
                    </PrivateRoute>
                }
            />
            <Route
                path="/privacypolicy"
                element={
                    <Layout>
                        <PrivacyPolicy />
                    </Layout>
                }
            />
            <Route
                path="/upload-documents"
                element={
                    <PrivateRoute>
                        <Layout>
                            <UploadDocuments />
                        </Layout>
                    </PrivateRoute>
                }
            />
            <Route
                path="*"
                element={
                    <Layout>
                        <Error404 />
                    </Layout>
                }
            />
        </Routes>
    );
}
