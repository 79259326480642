import React, { useCallback, useEffect, useState } from 'react'
import { Button, Container, Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { logout } from '../../auth/authSlice/authSlice'
import DocumentUploadForm from '../components/DocumentUploadForm/index.jsx'
import MessageAlert from '../components/MessageAlert'
import { getUserDocuments } from '../services/documentService'
import containerStyles from '../styles/containerStyles'

const SubirDocumentos = () => {
  const { authUser } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [documents, setDocuments] = useState([])
  const [cooldown, setCooldown] = useState(true)

  useEffect(() => {
    setLoading(true)
  }, [])

  const fetchUserDocuments = useCallback(async () => {
    if (!authUser || !authUser.userId || !authUser.token) return
    setLoading(true)
    try {
      const docs = await getUserDocuments(authUser.userId, authUser.token)
      setDocuments(docs)
    } catch (error) {
      console.error('Error fetching documents:', error)
      setMessage('Error al obtener los documentos del usuario.')
    } finally {
      setLoading(false)
    }
  }, [authUser])

  useEffect(() => {
    if (!authUser) {
      navigate('/')
    } else {
      setLoading(true)

      if (cooldown) {
        const timer = setTimeout(() => {
          setCooldown(false)
          fetchUserDocuments()
        }, 1000)
        return () => clearTimeout(timer)
      } else {
        fetchUserDocuments()
        setLoading(false)
      }
    }
  }, [authUser, navigate, fetchUserDocuments, cooldown])

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => setMessage(''), 5000)
      return () => clearTimeout(timer)
    }
  }, [message])

  const handleLogout = () => {
    dispatch(logout())
  }

  if (!authUser) return null

  return (
    <div style={containerStyles.centeredContainer}>
      <Container className="mt-4">
        <h2 style={{ textAlign: 'center' }}>Subir Documentos</h2>
        {message && <MessageAlert message={message} loading={loading} />}
        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <>
            <DocumentUploadForm authUser={authUser} setMessage={setMessage} fetchUserDocuments={fetchUserDocuments} documents={documents} />
            <div style={{ display: 'flex', justifyContent: 'center', gap: '1rem', marginTop: '1rem' }}>
              <Button onClick={fetchUserDocuments} disabled={loading}>
                Actualizar Documentos
              </Button>
              <Button onClick={handleLogout}>Cerrar Sesión</Button>
            </div>
          </>
        )}
      </Container>
    </div>
  )
}

export default SubirDocumentos
