import { ErrorMessage, Field, Formik, Form as FormikForm } from 'formik'
import React, { useEffect, useState } from 'react'
import { Alert, Button, Card, Col, Container, Form, InputGroup, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import Loading from '../../../common/pages/loading'
import ModalPasswordHelp from '../../../modal/ModalPasswordHelp'
import { loginActions } from '../../authSlice/loginActions'
import './style.css'

const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Correo electrónico inválido').required('Correo electrónico es requerido'),
  password: Yup.string().required('Contraseña es requerida'),
})

export default function Login() {
  const dispatch = useDispatch()
  const { isLoading, error, hasToken, authUser } = useSelector((state) => state.auth)
  const [isModalOpen, setModalOpen] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const handleOpenModal = () => {
    setModalOpen(true)
  }

  const handleModalClose = () => {
    setModalOpen(false)
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  useEffect(() => {
    if (hasToken && authUser) {
      navigate('/dashboard')
    }
  }, [hasToken, authUser, navigate])

  if (isLoading || loading) {
    return <Loading />
  }

  return (
    <Container>
      <Row className="justify-content-center">
        <Col xs={12} md={8} lg={6}>
          <Card className="my-5">
            <Card.Header className="text-center">Iniciar sesión</Card.Header>
            <Card.Body>
              <Formik
                initialValues={{ email: '', password: '' }}
                validationSchema={LoginSchema}
                onSubmit={async (values) => {
                  setLoading(true)
                  try {
                    const response = await dispatch(loginActions().login(values))

                    setLoading(false)

                    if (response.error?.message === 'Rejected' && response.payload === 'Request failed with status code 401') {
                      Alert.alert('Error en el inicio de sesión', 'Contraseña incorrecta. Por favor, intenta nuevamente.')
                    } else if (response.error) {
                      Alert.alert('Error en el inicio de sesión', 'Correo o contraseña incorrectos. Por favor, intenta nuevamente.')
                    }
                  } catch (error) {
                    setLoading(false)
                    Alert.alert('Error en el inicio de sesión', 'Correo o contraseña incorrectos. Por favor, intenta nuevamente.')
                  }
                }}
              >
                {({ errors, touched }) => (
                  <FormikForm>
                    {error && <div className="alert alert-danger">{error}</div>}
                    <Form.Group className="mb-3">
                      <Form.Label>Correo Electrónico</Form.Label>
                      <InputGroup>
                        <InputGroup.Text id="basic-addon1">@</InputGroup.Text>
                        <Field as={Form.Control} type="email" name="email" placeholder="Ingresa tu correo electrónico" isInvalid={touched.email && !!errors.email} />
                        <ErrorMessage name="email" component="div" className="invalid-feedback" />
                      </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Contraseña</Form.Label>
                      <InputGroup>
                        <Field as={Form.Control} type={showPassword ? 'text' : 'password'} name="password" placeholder="Ingresa tu contraseña" isInvalid={touched.password && !!errors.password} />
                        <Button variant="outline-secondary" onClick={togglePasswordVisibility}>
                          {showPassword ? 'Ocultar' : 'Mostrar'}
                        </Button>
                        <ErrorMessage name="password" component="div" className="invalid-feedback" />
                      </InputGroup>
                    </Form.Group>
                    <div className="mb-3 text-center">
                      <Button
                        style={{
                          color: 'inherit',
                          backgroundColor: 'transparent',
                          border: 'none',
                          textDecoration: 'underline',
                        }}
                        onClick={handleOpenModal}
                      >
                        ¿Olvidaste tu contraseña?
                      </Button>
                    </div>
                    <Button variant="dark" type="submit" className="w-100">
                      Entrar
                    </Button>
                  </FormikForm>
                )}
              </Formik>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {isModalOpen && <ModalPasswordHelp onClose={handleModalClose} />}
    </Container>
  )
}
