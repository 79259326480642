import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { getUsers } from '../users/services/userService';

const Dashboard = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const data = await getUsers();
        setUsers(data);
      } catch (error) {
        setError('Error fetching users');
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  if (loading) return <div>Cargando...</div>;
  if (error) return <div>{error}</div>;

  const totalUsers = users.length;
  const approvedUsers = users.filter((user) => user.verified).length;
  const pendingUsers = users.filter((user) => !user.verified && user.status).length;
  const rejectedUsers = users.filter((user) => !user.status).length;
  
  const totalDocuments = users.reduce((sum, user) => sum + (Number(user.documents) || 0), 0);
  const pendingDocuments = users.filter((user) => !user.verified).reduce((sum, user) => sum + (Number(user.documents) || 0), 0);
  const approvedDocuments = users.filter((user) => user.verified).reduce((sum, user) => sum + (Number(user.documents) || 0), 0);
  
  const avgDocumentsPerUser = totalUsers > 0 ? (totalDocuments / totalUsers).toFixed(2) : '0';

  return (
    <Container className="my-5">
      <Row className="mb-4">
        <Col>
          <h2>Dashboard</h2>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Card>
            <Card.Body>
              <Card.Title>Usuarios Registrados</Card.Title>
              <Card.Text>{totalUsers}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card>
            <Card.Body>
              <Card.Title>Usuarios Aprobados</Card.Title>
              <Card.Text>{approvedUsers}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card>
            <Card.Body>
              <Card.Title>Usuarios por Aprobar</Card.Title>
              <Card.Text>{pendingUsers}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card>
            <Card.Body>
              <Card.Title>Usuarios Rechazados</Card.Title>
              <Card.Text>{rejectedUsers}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card>
            <Card.Body>
              <Card.Title>Total de Documentos Subidos</Card.Title>
              <Card.Text>{totalDocuments}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card>
            <Card.Body>
              <Card.Title>Documentos Pendientes</Card.Title>
              <Card.Text>{pendingDocuments}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card>
            <Card.Body>
              <Card.Title>Documentos Aprobados</Card.Title>
              <Card.Text>{approvedDocuments}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card>
            <Card.Body>
              <Card.Title>Promedio de Documentos por Usuario</Card.Title>
              <Card.Text>{avgDocumentsPerUser}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
