import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const getUserDocuments = async (idUser, token) => {
  try {
    const response = await axios.get(`${API_URL}api/documents/user/${idUser}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data.documents;
  } catch (error) {
    console.error('Error fetching documents:', error);
    throw error;
  }
};

export const updateDocumentApproval = async (documentId, isApproved, id_user) => {
  const response = await axios.post(
    `${API_URL}api/documents/update`,
    {
      id_document: documentId,
      verify: isApproved,
      id_user: id_user
    },

  );
  return response.data;
};