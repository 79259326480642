import React, { useEffect, useState } from 'react'
import { Alert, Button, Form, FormControl, InputGroup, Modal } from 'react-bootstrap'
import { EyeFill, EyeSlashFill } from 'react-bootstrap-icons'
import { useSelector } from 'react-redux'
import { updatePassword } from '../api/api'

export default function ProfileModal({ show, handleClose }) {
  const [showChangePassword, setShowChangePassword] = useState(false)
  const [passwordSuccess, setPasswordSuccess] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [id_usuario, setId_usuario] = useState('')
  const { authUser } = useSelector((state) => state.auth) || {}

  useEffect(() => {
    if (authUser && authUser.userId) {
      setId_usuario(authUser.userId)
    }
  }, [authUser])

  const handleEditPasswordClick = () => {
    setShowChangePassword(!showChangePassword)
    setPasswordSuccess('')
    setPasswordError('')
  }

  const handleSaveNewPassword = async (e) => {
    e.preventDefault()
    if (newPassword === confirmPassword) {
      try {
        const response = await updatePassword(id_usuario, newPassword)
        if (response && response.message === 'Password updated successfully') {
          setShowChangePassword(false)
          setNewPassword('')
          setConfirmPassword('')
          setPasswordError('')
          setPasswordSuccess('Contraseña actualizada correctamente')
        } else {
          setPasswordError('Error al actualizar la contraseña, contacte al administrador')
        }
      } catch (error) {
        setPasswordError('Error al actualizar la contraseña contacte al administrador')
      }
    } else {
      setPasswordError('Las contraseñas no coinciden')
    }
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  return (
    <Modal show={show} onHide={handleClose} size="">
      <Modal.Header closeButton>
        <Modal.Title>Perfil del Usuario</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Nombre: {authUser?.personName || 'N/A'}</p>
        <p>Apellido: {authUser?.personLastName || 'N/A'}</p>
        <p>Email: {authUser?.userMail || 'N/A'}</p>

        <Button variant="primary" className="my-3" style={{ borderColor: 'white' }} onClick={handleEditPasswordClick}>
          Editar Contraseña
        </Button>

        {showChangePassword && (
          <>
            <Form>
              <Form.Group controlId="formNewPassword">
                <Form.Label>Nueva Contraseña</Form.Label>
                <InputGroup>
                  <Form.Control type={showPassword ? 'text' : 'password'} placeholder="Ingresa tu nueva contraseña" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                  <Button variant="outline-secondary" onClick={togglePasswordVisibility}>
                    {showPassword ? <EyeSlashFill /> : <EyeFill />}
                  </Button>
                </InputGroup>
              </Form.Group>

              <Form.Group controlId="formConfirmNewPassword">
                <Form.Label>Confirmar Nueva Contraseña</Form.Label>
                <FormControl type={showPassword ? 'text' : 'password'} placeholder="Confirma tu nueva contraseña" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
              </Form.Group>
              <Button variant="success" className="mt-3" onClick={handleSaveNewPassword}>
                Guardar Nueva Contraseña
              </Button>
            </Form>
            {passwordError && (
              <Alert variant="danger" className="mt-3">
                {passwordError}
              </Alert>
            )}
          </>
        )}
        {passwordSuccess && (
          <Alert variant="success" className="mt-3">
            {passwordSuccess}
          </Alert>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
