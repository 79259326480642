import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const exportUser = async (idUser, token) => {
    try {
        const response = await axios.post(
            `${API_URL}api/users/export-user`,
            { id_user: idUser },
        );
        return response.data;
    } catch (error) {
        console.error('Error exporting user:', error);
        throw error;
    }
};
