import { Delete, Edit, Visibility } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Container, Dropdown, DropdownButton, Row, Table } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import ApprovedUsersTable from '../components/ApprovedUsersTable'
import EditUserModal from '../components/EditUserModal'
import ExportedUsersTable from '../components/ExportedUsersTable'
import UserModal from '../components/UserModal'
import { getUsers } from '../services/userService'
import { filterUsersByRole } from '../utils/userUtils'

export default function UserTables() {
  const [users, setUsers] = useState([])
  const [approvedUsers, setApprovedUsers] = useState([])
  const [exportedUsers, setExportedUsers] = useState([])
  const [showUserModal, setShowUserModal] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)
  const [showEditModal, setShowEditModal] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [selectedBroker, setSelectedBroker] = useState('euroforexlatam')
  const { authUser } = useSelector((state) => state.auth)

  const token = authUser.token

  const fetchUsers = async () => {
    try {
      const data = await getUsers(token)
      const filteredUsers = filterUsersByRole(data, [2, 3])
      const approved = filteredUsers.filter((user) => user.verified === true && user.export !== true)
      setApprovedUsers(approved)
      const notApproved = filteredUsers.filter((user) => user.verified !== true)
      setUsers(notApproved)
      const exported = filteredUsers.filter((user) => user.export === true)
      setExportedUsers(exported)
    } catch (error) {
      setError('Error fetching users')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchUsers()
  }, [token])

  const handleShowUserModal = (user) => {
    setSelectedUser(user)
    setShowUserModal(true)
  }

  const handleCloseUserModal = () => {
    setShowUserModal(false)
    setSelectedUser(null)
  }

  const handleShowEditModal = (user) => {
    setSelectedUser(user)
    setShowEditModal(true)
  }

  const handleCloseEditModal = () => {
    setShowEditModal(false)
    setSelectedUser(null)
  }

  const handleSaveUser = (updatedUser) => {
    setUsers((prevUsers) => prevUsers.map((user) => (user.id_user === updatedUser.id_user ? updatedUser : user)))
    setShowEditModal(false)
    setSelectedUser(null)
  }

  const handleDeleteUser = (userId) => {
    setUsers((prevUsers) => prevUsers.filter((user) => user.id_user !== userId))
    setApprovedUsers((prevApprovedUsers) => prevApprovedUsers.filter((user) => user.id_user !== userId))
    setExportedUsers((prevExportedUsers) => prevExportedUsers.filter((user) => user.id_user !== userId))
  }

  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(value)
    setCurrentPage(1)
  }

  const handleUserApproved = () => {
    fetchUsers()
  }

  const handleBrokerChange = (broker) => {
    setSelectedBroker(broker)
  }

  const allDocumentsApproved = (user) => {
    if (!user.documents) return false
    const requiredDocs = ['personalInfo', 'utility', 'bancario']
    const approvedDocs = user.documents.filter((doc) => doc.verify === 'true')
    return requiredDocs.every((type) => approvedDocs.some((doc) => doc.document_type === type))
  }

  const getUsersByBroker = (userList) => {
    return userList.filter((user) => user.broker === selectedBroker)
  }

  if (loading) return <div>Cargando...</div>
  if (error) return <div>{error}</div>

  return (
    <Container className="my-5">
      <Row className="justify-content-center mb-3">
        <Col md={8}>
          <DropdownButton id="dropdown-basic-button" title="Seleccionar Servicio" onSelect={handleBrokerChange} className="w-100">
            <Dropdown.Item eventKey="euroforexlatam">Euroforex Latam</Dropdown.Item>
            <Dropdown.Item eventKey="euroforexglobal">Euroforex Global</Dropdown.Item>
            <Dropdown.Item eventKey="servicionuevo">Servicio Nuevo</Dropdown.Item>
          </DropdownButton>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={8}>
          <Card>
            <Card.Header className="d-flex justify-content-between align-items-center">
              <span>Lista de Usuarios - {selectedBroker}</span>
              <DropdownButton id="dropdown-basic-button" title={`Mostrar ${itemsPerPage}`} onSelect={handleItemsPerPageChange}>
                <Dropdown.Item eventKey={10}>10</Dropdown.Item>
                <Dropdown.Item eventKey={20}>20</Dropdown.Item>
                <Dropdown.Item eventKey={30}>30</Dropdown.Item>
              </DropdownButton>
            </Card.Header>
            <Card.Body>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Nombre</th>
                    <th>Email</th>
                    <th>Teléfono</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {getUsersByBroker(users).map((user) => (
                    <tr key={user.id_user}>
                      <td>{user.id_user}</td>
                      <td>
                        {user.person.first_name} {user.person.last_name}
                      </td>
                      <td>{user.email}</td>
                      <td>{user.phone}</td>
                      <td>
                        <Button variant="info" className="mx-1" onClick={() => handleShowUserModal(user)}>
                          <Visibility />
                        </Button>
                        <Button variant="warning" className="mx-1" onClick={() => handleShowEditModal(user)}>
                          <Edit />
                        </Button>
                        <Button variant="danger" className="mx-1" onClick={() => handleDeleteUser(user.id_user)}>
                          <Delete />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
        {approvedUsers.length > 0 && <ApprovedUsersTable approvedUsers={getUsersByBroker(approvedUsers)} handleShowUserModal={handleShowUserModal} handleShowEditModal={handleShowEditModal} handleDeleteUser={handleDeleteUser} token={token} />}
        {exportedUsers.length > 0 && <ExportedUsersTable approvedUsers={getUsersByBroker(exportedUsers)} handleShowUserModal={handleShowUserModal} handleShowEditModal={handleShowEditModal} handleDeleteUser={handleDeleteUser} token={token} exportLabel="Volver a exportar" />}
      </Row>
      {selectedUser && (
        <>
          <UserModal show={showUserModal} user={selectedUser} handleClose={handleCloseUserModal} onUserApproved={handleUserApproved} hideApproveButton={selectedUser?.verified} />
          <EditUserModal show={showEditModal} user={selectedUser} handleClose={handleCloseEditModal} handleSave={handleSaveUser} />
        </>
      )}
    </Container>
  )
}
