import axios from 'axios';

const API_URL = `${process.env.REACT_APP_API_URL}api/users`;
export const getUsers = async () => {
    try {
        const response = await axios.get(API_URL)
        return response.data.usuarios
    } catch (error) {
        console.error('Error fetching users:', error);
        throw error;
    }
};
