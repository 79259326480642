import React from 'react'
import { Button, Modal } from 'react-bootstrap'

export default function CustomModal({ show, handleClose, title, body, img }) {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      {img && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img src={img} alt={title} style={{ width: '40%', marginBottom: '20px', marginTop: '1rem' }} />
        </div>
      )}
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
