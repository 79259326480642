import { objectToUser } from './userMapper';

export function objectToAuthUser(object) {
    return {
        userId: object.user.id_usuario,
        fullName: `${object.user.nombre} ${object.user.apellido}`,
        user: objectToUser(object.user),
        token: { accessToken: object.token },
        broker: object.user.broker,
    };
}
