import React from 'react'
import { Modal, Button } from 'react-bootstrap'

function ModalAcceso({ showModal, handleCloseModal }) {
  return (
    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Acceso a Cuentas Demo y Reales</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Con nuestra plataforma, puedes acceder a cuentas demo y reales para mejorar tus habilidades de trading y gestionar tus inversiones. Ofrecemos:</p>
        <ul>
          <li>
            <strong>Acceso Rápido:</strong> Crea y gestiona tus cuentas demo y reales de manera eficiente con nuestro sistema fácil de usar.
          </li>
          <li>
            <strong>Interfaz Intuitiva:</strong> Navega y realiza operaciones sin complicaciones gracias a nuestro diseño amigable.
          </li>
          <li>
            <strong>Compatibilidad Móvil:</strong> Accede y gestiona tus cuentas desde tu smartphone o tablet con nuestra aplicación móvil.
          </li>
          <li>
            <strong>Soporte Multicanal:</strong> Obtén ayuda y asesoramiento a través de varios canales de soporte, incluyendo chat en vivo y teléfono.
          </li>
        </ul>
        <p>Con nuestro servicio de acceso a cuentas demo y reales, puedes practicar y operar en el mercado de manera segura y accesible.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalAcceso
