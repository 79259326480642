import { ErrorMessage, Field, Formik, Form as FormikForm } from 'formik'
import { useEffect, useState } from 'react'
import { Alert, Button, Card, Col, Container, Form, InputGroup, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import Loading from '../../../common/pages/loading'
import { loginActions } from '../../authSlice/loginActions'
import { registerActions } from '../../authSlice/registerActions'
import CustomModal from '../../components/CustomModal'
import modalTexts from '../../data/modalTexts.json'
import './style.css'

const RegisterSchema = Yup.object().shape({
  email: Yup.string().email('Correo electrónico inválido').required('Correo electrónico es requerido'),
  password: Yup.string().required('Contraseña es requerida'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Las contraseñas deben coincidir')
    .required('Confirmar contraseña es requerido'),
  first_name: Yup.string().required('Nombre es requerido'),
  last_name: Yup.string().required('Apellido es requerido'),
  phone: Yup.string().required('Número de celular es requerido'),
  address: Yup.string().required('Dirección es requerida'),
  broker: Yup.string(),
})

const InputField = ({ label, name, type, placeholder, children }) => (
  <Form.Group className="mb-3">
    <Form.Label>{label}</Form.Label>
    <InputGroup>
      <Field as={Form.Control} type={type} name={name} placeholder={placeholder} />
      {children}
      <ErrorMessage name={name} component="div" className="invalid-feedback" />
    </InputGroup>
  </Form.Group>
)

export default function Register() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { isLoading, error } = useSelector((state) => state.auth)
  const [showPassword, setShowPassword] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [modalContent, setModalContent] = useState({})
  const queryParams = new URLSearchParams(location.search)
  const broker = queryParams.get('broker') || ''

  const validIds = {
    'EFL-283X7B6': { name: 'euroforexlatam', img: require('../../../assets/euroforexlatam.jpeg') },
    'EFG-9A2YQ3Z': { name: 'euroforexglobal', img: require('../../../assets/euroforexglobal.jpeg') },
    'SNV-4L5KZ8J': { name: 'servicionuevo', img: require('../../../assets/euroforexglobal.jpeg') },
  }

  const serviceName = validIds[broker]

  useEffect(() => {
    if (serviceName) {
      setModalContent({
        ...modalTexts[serviceName.name],
        img: serviceName.img,
      })
      setShowModal(true)
    } else {
      navigate('/')
    }
  }, [])

  useEffect(() => {
    if (!serviceName) {
      navigate('/')
    }
  }, [])

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <Container>
      <Row className="justify-content-center">
        <Col xs={12} md={8} lg={6}>
          <Card className="my-5">
            <Card.Header className="text-center">Registro de usuario</Card.Header>
            <Card.Body>
              <Formik
                initialValues={{ email: '', password: '', confirmPassword: '', first_name: '', last_name: '', phone: '', address: '', broker: serviceName || '' }} // Incluye el broker obtenido del ID
                validationSchema={RegisterSchema}
                onSubmit={async (values, { setSubmitting }) => {
                  setSubmitting(true)

                  try {
                    const response = await dispatch(registerActions().register(values))

                    if (response.error) {
                      setAlertMessage(response.payload)
                    } else {
                      const responseLogin = await dispatch(loginActions().login({ email: values.email, password: values.password }))

                      if (!responseLogin.error) {
                        window.location.reload()
                      } else {
                        setAlertMessage('Registro exitoso, pero falló el inicio de sesión')
                      }
                    }
                  } catch (error) {
                    setAlertMessage(error.message)
                  } finally {
                    setSubmitting(false)
                  }
                }}
              >
                {({ isSubmitting }) => (
                  <FormikForm>
                    {error && <Alert variant="danger">{error}</Alert>}
                    {alertMessage && <Alert variant="danger">{alertMessage}</Alert>}
                    <InputField label="Correo Electrónico" name="email" type="email" placeholder="Ingresa tu correo electrónico" />
                    <InputField label="Contraseña" name="password" type={showPassword ? 'text' : 'password'} placeholder="Ingresa tu contraseña">
                      <Button variant="outline-secondary" onClick={togglePasswordVisibility}>
                        {showPassword ? 'Ocultar' : 'Mostrar'}
                      </Button>
                    </InputField>
                    <InputField label="Confirmar Contraseña" name="confirmPassword" type={showPassword ? 'text' : 'password'} placeholder="Confirma tu contraseña">
                      <Button variant="outline-secondary" onClick={togglePasswordVisibility}>
                        {showPassword ? 'Ocultar' : 'Mostrar'}
                      </Button>
                    </InputField>
                    <Row>
                      <Col xs={6}>
                        <InputField label="Nombre" name="first_name" type="text" placeholder="Ingresa tu nombre" />
                      </Col>
                      <Col xs={6}>
                        <InputField label="Apellido" name="last_name" type="text" placeholder="Ingresa tu apellido" />
                      </Col>
                    </Row>
                    <InputField label="Número de Celular" name="phone" type="text" placeholder="Ingresa tu número de celular" />
                    <InputField label="Dirección" name="address" type="text" placeholder="Ingresa tu dirección" />
                    <Button variant="dark" type="submit" className="w-100" disabled={isSubmitting}>
                      {isSubmitting ? 'Registrando...' : 'Registarme'}
                    </Button>
                  </FormikForm>
                )}
              </Formik>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <CustomModal show={showModal} handleClose={handleCloseModal} title={modalContent.title} body={modalContent.body} img={modalContent.img} />
    </Container>
  )
}
