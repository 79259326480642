import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { jwtDecode } from 'jwt-decode';
import { TOKEN } from '../../config/constants';
import { deleteSecureItem, getSecuredItem } from '../../utils/secureStorage';
import { loginActions } from './loginActions';

export const initializeAuth = createAsyncThunk(
    'auth/initializeAuth',
    async (_, { rejectWithValue }) => {
        try {
            const token = getSecuredItem(TOKEN);
            if (token) {
                const isTokenExpired = (token) => {
                    try {
                        const decoded = jwtDecode(token);
                        const currentTime = Date.now() / 1000;
                        return decoded.exp < currentTime;
                    } catch (error) {
                        return rejectWithValue('Invalid date toekn')
                    }
                };
                if (!isTokenExpired(token)) {
                    const user = jwtDecode(token)
                    if (user) {
                        return { user, hasToken: true }
                    } else {
                        await deleteSecureItem(token)
                        return rejectWithValue('Invalid token')
                    }
                }
            }
            return { user: null, hasToken: false };
        } catch (error) {
            return rejectWithValue('Error initializing auth')
        }
    }
);

const initialState = {
    authUser: null,
    hasToken: false,
    isLoading: false,
    error: null,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: (state) => {
            state.authUser = null;
            state.hasToken = false;
            deleteSecureItem(TOKEN);
        },
        setHasToken: (state, action) => {
            state.hasToken = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loginActions().login.pending, loginActions().handlePending);
        builder.addCase(loginActions().login.fulfilled, loginActions().handleFulfilled);
        builder.addCase(loginActions().login.rejected, loginActions().handleRejected);
        builder.addCase(initializeAuth.fulfilled, (state, action) => {
            state.authUser = action.payload.user;
            state.hasToken = action.payload.hasToken;
        });
        builder.addCase(initializeAuth.rejected, (state, action) => {
            state.error = action.payload;
        });
    },
});

export const { logout, setHasToken } = authSlice.actions;
export default authSlice.reducer;
