const containerStyles = {
    centeredContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

    },
};

export default containerStyles;
