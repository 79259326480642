import { createApi } from '@reduxjs/toolkit/query/react'
import axios from 'axios'

const API_PATH_PREFIX = 'api'

const axiosConfig = {
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': 'application/json' },
}

const instance = axios.create(axiosConfig)

const axiosBaseQuery =
    ({ baseUrl, axiosInstance } = { baseUrl: '', axiosInstance: instance }) =>
        async ({ url, method, data, params }) => {
            try {
                const result = await axiosInstance({ url: `${baseUrl}${url}`, method, data, params })
                return { data: result.data }
            } catch (axiosError) {
                console.error(axiosError)
                return {
                    error: {
                        status: axiosError.response?.status,
                        data: axiosError.response?.data || axiosError.message,
                    },
                }
            }
        }

const Api = createApi({
    reducerPath: 'Api',
    baseQuery: axiosBaseQuery({ baseUrl: API_PATH_PREFIX, axiosInstance: instance }),
    endpoints: () => ({}),
})

export { API_PATH_PREFIX, Api, instance }

