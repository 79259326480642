import axios from 'axios';

const API_URL = `${process.env.REACT_APP_API_URL}api/documents/`;

const uploadDocument = async (file, idUser, documentType) => {
  const formData = new FormData();
  formData.append('id_user', idUser);
  formData.append('document_type', documentType);
  formData.append('file', file);

  try {
    const response = await axios.post(API_URL, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error;
  }
};

export default uploadDocument;

export const getUserDocuments = async (idUser, token) => {
  try {
    const response = await axios.get(`${API_URL}user/${idUser}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data.documents;
  } catch (error) {
    console.error('Error fetching documents:', error);
    throw error;
  }
};

export const updateDocument = async (documentId, file, documentType) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('document_type', documentType);

  const response = await axios.put(`${API_URL}${documentId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });

  return response.data;
};