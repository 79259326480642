export const handleUseCaseError = (error) => {
    return toErrorWithMessage(error).message;
}

function toErrorWithMessage(maybeError) {
    if (isErrorWithMessage(maybeError)) {
        return maybeError;
    }

    try {
        return new Error(JSON.stringify(maybeError));
    } catch {
        return new Error(String(maybeError));
    }
}

function isErrorWithMessage(error) {
    return typeof error === 'object' && error !== null && 'message' in error && typeof error.message === 'string';
}
