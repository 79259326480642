import { faEnvelope, faFilePdf, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState, useCallback } from 'react'
import { Alert, Button, Modal, Spinner } from 'react-bootstrap'
import { getUserDocuments, updateDocumentApproval } from '../../services/documentService'
import { approveUser } from '../../services/approveUserService'
import ConfirmationModal from '../ConfirmationModal'
import PDFViewer from '../PDFViewer'
import styles from './style'

const buttonStyles = {
  approved: {
    backgroundColor: 'green',
    color: 'black',
    borderColor: 'green',
  },
  pending: {
    backgroundColor: '#6c757d',
    color: 'white',
    borderColor: '#6c757d',
  },
}

const documentNames = {
  personalInfo: 'Información Personal',
  utility: 'Factura de Servicios',
  bancario: 'Extracto Bancario',
}

const UserModal = ({ show, user, handleClose, onUserApproved, hideApproveButton }) => {
  const [confirmModal, setConfirmModal] = useState({ show: false, action: null, pdf: null })
  const [selectedPDF, setSelectedPDF] = useState(null)
  const [pdfFiles, setPdfFiles] = useState([])
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [messageVariant, setMessageVariant] = useState('danger') // Por defecto en 'danger' para errores

  const proxyUrl = process.env.REACT_APP_PROXY_URL

  const fetchUserDocuments = useCallback(async () => {
    setLoading(true)
    setMessage('')
    try {
      const documents = await getUserDocuments(user.id_user)
      const filteredDocuments = documents.filter((doc) => doc.document_type === 'personalInfo' || doc.document_type === 'utility' || doc.document_type === 'bancario')
      setPdfFiles(filteredDocuments)
    } catch (error) {
      setMessage('Error al obtener los documentos del usuario.')
      setMessageVariant('danger')
    } finally {
      setLoading(false)
    }
  }, [user.id_user])

  useEffect(() => {
    if (show) {
      fetchUserDocuments()
    }
  }, [show, fetchUserDocuments])

  const handleApprove = (pdf) => {
    setConfirmModal({
      show: true,
      action: 'approve',
      pdf,
    })
  }

  const handleReject = (pdf) => {
    setConfirmModal({
      show: true,
      action: 'reject',
      pdf,
    })
  }

  const handleConfirm = async () => {
    const isApproved = confirmModal.action === 'approve'
    console.log(confirmModal)
    try {
      await updateDocumentApproval(confirmModal.pdf.id_document, isApproved, confirmModal.pdf.id_user)
      setMessage(`PDF ${confirmModal.pdf.document_name} ${isApproved ? 'aprobado' : 'rechazado'}`)
      setMessageVariant('success')
      fetchUserDocuments()
    } catch (error) {
      setMessage('Error al actualizar el estado del documento.')
      setMessageVariant('danger')
    } finally {
      setConfirmModal({ show: false, action: null, pdf: null })
    }
  }

  const handleApproveUser = async () => {
    try {
      await approveUser(user.id_user, user.token)
      setMessage('Usuario aprobado exitosamente.')
      setMessageVariant('success')
      if (onUserApproved) {
        onUserApproved()
      }
    } catch (error) {
      setMessage('Error al aprobar el usuario.')
      setMessageVariant('danger')
    }
  }

  const translateDocumentType = (type) => {
    switch (type) {
      case 'personalInfo':
        return 'Información Personal'
      case 'utility':
        return 'Factura de Servicios'
      case 'bancario':
        return 'Documento Bancario'
      default:
        return type
    }
  }

  const allDocumentsApproved = pdfFiles.every((file) => file.verify === 'true')

  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg" dialogClassName="centered-modal">
        <Modal.Header closeButton>
          <Modal.Title>Detalles del Usuario</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={styles.userDetails}>
            <p>
              <FontAwesomeIcon icon={faUser} /> <strong>Nombre:</strong> {user.person.first_name} {user.person.last_name}
            </p>
            <p>
              <FontAwesomeIcon icon={faEnvelope} /> <strong>Email:</strong> {user.email}
            </p>
            <p>
              <strong>Documentos:</strong> {pdfFiles.length}
            </p>
            <p>
              <strong>Usuario aprobado:</strong> {user.verified ? 'Aprobado' : 'Pendiente'}
            </p>
          </div>
          {loading && <Spinner animation="border" />}
          {message && <Alert variant={messageVariant}>{message}</Alert>} {/* Usar messageVariant para determinar el color */}
          {!loading && (
            <div style={styles.pdfGrid}>
              {pdfFiles.map((file) => (
                <Button key={file.id_document} onClick={() => setSelectedPDF(selectedPDF?.id_document === file.id_document ? null : file)} style={file.verify === 'true' ? buttonStyles.approved : buttonStyles.pending} className="w-100 text-center mb-2">
                  <FontAwesomeIcon icon={faFilePdf} /> {file.document_name} ({translateDocumentType(file.document_type)}) - {file.verify === 'true' ? 'Aprobado' : 'Por revisar'}
                </Button>
              ))}
              {selectedPDF && <PDFViewer key={selectedPDF.id_document} file={`${proxyUrl}?url=${encodeURIComponent(selectedPDF.document_url)}`} index={selectedPDF.id_document} handleApprove={() => handleApprove(selectedPDF)} handleReject={() => handleReject(selectedPDF)} />}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          {allDocumentsApproved && (
            <Button variant="success" onClick={handleApproveUser}>
              Aprobar Usuario
            </Button>
          )}
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

      <ConfirmationModal
        show={confirmModal.show}
        handleClose={() => setConfirmModal({ show: false, action: null, pdf: null })}
        handleConfirm={handleConfirm}
        message={
          confirmModal.pdf
            ? confirmModal.action === 'approve'
              ? `¿Está seguro de que desea aprobar el PDF ${documentNames[confirmModal.pdf.document_type]}?`
              : `Al rechazar este PDF se le pedirá al usuario que suba nuevamente el PDF: ${documentNames[confirmModal.pdf.document_type]}. ¿Está seguro de realizar esta acción?`
            : ''
        }
      />
    </>
  )
}

export default UserModal
