const styles = {
    pdfViewerContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
    },
    pdfItem: {
      marginBottom: '20px',
      border: '1px solid #ddd',
      padding: '10px',
      borderRadius: '5px',
      maxWidth: '80%', // Ajusta según sea necesario
      maxHeight: '80vh', // Limitar la altura máxima del contenedor
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      overflow: 'auto',
    },
    spinnerContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100px',
    },
    controlsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '80%',
      marginBottom: '10px',
    },
    navigationContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    zoomContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    pageIndicator: {
      margin: '0 10px',
    },
    zoomIndicator: {
      margin: '0 10px',
    },
    pdfButtonsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '10px',
      width: '80%', // Asegurar que los botones de aprobación/rechazo ocupen el ancho completo
    },
    pdfButtons: {
      margin: '0 5px',
    },
    pageWidth: 600, // Ajuste del tamaño del PDF
  }
  
  export default styles
  