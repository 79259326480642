import React, { useState } from 'react'
import { Button, Spinner } from 'react-bootstrap'
import { Document, Page, pdfjs } from 'react-pdf'
import styles from './style'

// Configura pdfjs
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

const PDFViewer = ({ file, index, handleApprove, handleReject }) => {
  const [loading, setLoading] = useState(true)
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [scale, setScale] = useState(1.0)

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
    setLoading(false)
  }

  const goToPreviousPage = () => {
    setPageNumber((prevPageNumber) => Math.max(prevPageNumber - 1, 1))
  }

  const goToNextPage = () => {
    setPageNumber((prevPageNumber) => Math.min(prevPageNumber + 1, numPages))
  }

  const zoomIn = () => {
    setScale((prevScale) => prevScale + 0.25)
  }

  const zoomOut = () => {
    setScale((prevScale) => Math.max(prevScale - 0.25, 0.5))
  }

  return (
    <div style={styles.pdfViewerContainer}>
      <div style={styles.pdfItem}>
        {loading && (
          <div style={styles.spinnerContainer}>
            <Spinner animation="border" />
          </div>
        )}
        <Document file={file} onLoadSuccess={onDocumentLoadSuccess} onLoadError={(error) => console.error('Error loading document:', error)} loading={<Spinner animation="border" />}>
          <Page pageNumber={pageNumber} scale={scale} renderTextLayer={false} renderAnnotationLayer={false} />
        </Document>
      </div>
      <div style={styles.controlsContainer}>
        <div style={styles.navigationContainer}>
          <Button variant="secondary" onClick={goToPreviousPage} disabled={pageNumber <= 1}>
            Anterior
          </Button>
          <span style={styles.pageIndicator}>
            Página {pageNumber} de {numPages}
          </span>
          <Button variant="secondary" onClick={goToNextPage} disabled={pageNumber >= numPages}>
            Siguiente
          </Button>
        </div>
        <div style={styles.zoomContainer}>
          <Button variant="secondary" onClick={zoomOut} disabled={scale <= 0.5}>
            -
          </Button>
          <span style={styles.zoomIndicator}>Zoom: {(scale * 100).toFixed(0)}%</span>
          <Button variant="secondary" onClick={zoomIn}>
            +
          </Button>
        </div>
      </div>
      <div style={styles.pdfButtonsContainer}>
        <Button style={styles.pdfButtons} variant="success" onClick={() => handleApprove(index)}>
          Aprobar
        </Button>
        <Button style={styles.pdfButtons} variant="danger" onClick={() => handleReject(index)}>
          Rechazar
        </Button>
      </div>
    </div>
  )
}

export default PDFViewer
