
export function objectToUser(object) {
    return {
        id: object.id_usuario,
        name: object.nombre,
        surname: object.apellido,
        address: object.direccion,
        email: object.email,
        phone: object.telefono,
        
    };
}
