import React, { useState, useEffect } from 'react'
import { Alert, Button, Form, Modal } from 'react-bootstrap'
import uploadDocument, { updateDocument } from '../../services/documentService'
import formStyles from '../../styles/formStyles'

const DocumentUploadForm = ({ authUser, setMessage, fetchUserDocuments, documents = [] }) => {
  const [showModal, setShowModal] = useState(true)

  const handleCloseModal = () => setShowModal(false)

  useEffect(() => {
    setShowModal(true)
  }, [])

  const handleSubmit = async (event) => {
    event.preventDefault()
    setMessage('')
    const formData = new FormData(event.target)
    const idDocumentFile = formData.get('idDocument')
    const utilityBillFile = formData.get('utilityBill')
    const bankStatementsFile = formData.get('bankStatements')

    try {
      if (idDocumentFile && idDocumentFile.size > 0) {
        if (personalInfoStatus.status === 'rejected') {
          setMessage('Actualizando documento de identidad...')
          await updateDocument(personalInfoStatus.id_document, idDocumentFile, 'personalInfo')
        } else {
          setMessage('Subiendo documento de identidad...')
          await uploadDocument(idDocumentFile, authUser.userId, 'personalInfo')
        }
        setMessage('Documento de identidad subido exitosamente.')
      }
      if (utilityBillFile && utilityBillFile.size > 0) {
        if (utilityStatus.status === 'rejected') {
          setMessage('Actualizando factura de servicios...')
          await updateDocument(utilityStatus.id_document, utilityBillFile, 'utility')
        } else {
          setMessage('Subiendo factura de servicios...')
          await uploadDocument(utilityBillFile, authUser.userId, 'utility')
        }
        setMessage('Factura de servicios subida exitosamente.')
      }
      if (bankStatementsFile && bankStatementsFile.size > 0) {
        if (bankStatus.status === 'rejected') {
          setMessage('Actualizando extractos bancarios...')
          await updateDocument(bankStatus.id_document, bankStatementsFile, 'bancario')
        } else {
          setMessage('Subiendo extractos bancarios...')
          await uploadDocument(bankStatementsFile, authUser.userId, 'bancario')
        }
        setMessage('Extractos bancarios subidos exitosamente.')
      }
      setMessage('Todos los archivos se han subido exitosamente.')
      fetchUserDocuments()
    } catch (error) {
      console.error('Error uploading files:', error)
      setMessage('Error al subir los archivos.')
    }
  }

  const getDocumentStatus = (type) => {
    if (!documents) return { status: 'none' }
    const doc = documents.find((doc) => doc.document_type === type)
    if (doc && doc.verify === 'true') return { status: 'approved', name: doc.document_url.split('/').pop(), id_document: doc.id_document }
    if (doc && doc.verify === 'false') return { status: 'rejected', id_document: doc.id_document }
    if (doc && doc.verify === 'pending') return { status: 'pending' }
    return { status: 'none' }
  }

  const personalInfoStatus = getDocumentStatus('personalInfo')
  const utilityStatus = getDocumentStatus('utility')
  const bankStatus = getDocumentStatus('bancario')

  return (
    <>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Importante</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Por favor, asegúrese de que los documentos PDF que va a subir sean legibles y estén completos. Los documentos ilegibles o incompletos podrían ser rechazados.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseModal}>
            Entendido
          </Button>
        </Modal.Footer>
      </Modal>

      <Form onSubmit={handleSubmit} style={formStyles.form}>
        <Form.Group className="mb-3">
          <Form.Label>Documento de Identidad (Cédula, DNI o Pasaporte)</Form.Label>
          {personalInfoStatus.status === 'approved' && <Alert variant="success">Documento Aprobado</Alert>}
          {personalInfoStatus.status === 'pending' && <Alert variant="warning">Documento en Revisión</Alert>}
          {personalInfoStatus.status === 'rejected' || personalInfoStatus.status === 'none' ? <Form.Control type="file" name="idDocument" accept="application/pdf" /> : null}{' '}
          {personalInfoStatus.status === 'rejected' && (
            <Alert style={formStyles.alertError} variant="danger">
              Documento Rechazado. Por favor vuelva a subirlo.
            </Alert>
          )}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Factura de Servicios Públicos (Agua, Electricidad, Internet)</Form.Label>
          {utilityStatus.status === 'approved' && <Alert variant="success">Documento Aprobado</Alert>}
          {utilityStatus.status === 'pending' && <Alert variant="warning">Documento en Revisión</Alert>}
          {utilityStatus.status === 'rejected' || utilityStatus.status === 'none' ? <Form.Control type="file" name="utilityBill" accept="application/pdf" /> : null}{' '}
          {utilityStatus.status === 'rejected' && (
            <Alert style={formStyles.alertError} variant="danger">
              Documento Rechazado. Por favor vuelva a subirlo.
            </Alert>
          )}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Extractos Bancarios</Form.Label>
          {bankStatus.status === 'approved' && <Alert variant="success">Documento Aprobado</Alert>}
          {bankStatus.status === 'pending' && <Alert variant="warning">Documento en Revisión</Alert>}
          {bankStatus.status === 'rejected' || bankStatus.status === 'none' ? <Form.Control type="file" name="bankStatements" accept="application/pdf" /> : null}
          {bankStatus.status === 'rejected' && (
            <Alert style={formStyles.alertError} variant="danger">
              Documento Rechazado. Por favor vuelva a subirlo.
            </Alert>
          )}
        </Form.Group>
        <Button type="submit">Subir</Button>
      </Form>
    </>
  )
}

export default DocumentUploadForm
