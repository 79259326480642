import { Visibility } from '@material-ui/icons';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import React from 'react';
import { Button, Card, Col, ListGroup, Row } from 'react-bootstrap';
import { getUserDocuments } from '../../services/documentService';
import { exportUser } from '../../services/exportUserService';

const ApprovedUsersTable = ({ approvedUsers, handleShowUserModal, token, exportLabel = "Exportar usuario y documentos" }) => {
  const handleDownloadUserPDF = async (user) => {
    try {
      const exportResponse = await exportUser(user.id_user, token);

      if (exportResponse) {
        const userData = `
Nombre: ${user.person.first_name || 'N/A'}
Apellido: ${user.person.last_name || 'N/A'}
Email: ${user.email}
Número de Teléfono: ${user.person.phone || 'N/A'}
        `;
        const userBlob = new Blob([userData], { type: 'text/plain' });

        const zip = new JSZip();
        zip.file('datosUsuario.txt', userBlob);

        const documents = await getUserDocuments(user.id_user, token);

        if (documents && documents.length > 0) {
          for (const document of documents) {
            try {
              const proxyUrl = `http://localhost:5200/proxy?url=${encodeURIComponent(document.document_url)}`;
              const response = await fetch(proxyUrl);
              if (!response.ok) {
                throw new Error(`Error fetching document: ${response.statusText}`);
              }
              const pdfBlob = await response.blob();
              zip.file(`${document.document_type}_${document.id_document}.pdf`, pdfBlob);
            } catch (error) {
              console.error(`Failed to fetch document ${document.document_url}:`, error);
            }
          }
        }

        const content = await zip.generateAsync({ type: 'blob' });
        saveAs(content, `${user.email}_documents.zip`);
      } else {
        console.error('Error exporting user:', exportResponse.statusText);
      }
    } catch (error) {
      console.error('Failed to export user and download PDF:', error);
    }
  };

  return (
    <Row className="justify-content-center mt-5">
      <Col md={8}>
        <Card>
          <Card.Header className="d-flex justify-content-between align-items-center">
            <span>Usuarios Aprobados</span>
          </Card.Header>
          <Card.Body>
            <ListGroup>
              {approvedUsers.map((user) => (
                <ListGroup.Item key={user.id_user} className="d-flex justify-content-between align-items-center">
                  <span>{user.email}</span>
                  <div>
                    <Button variant="info" className="mx-1" onClick={() => handleShowUserModal(user)}>
                      <Visibility />
                    </Button>
                    <Button variant="primary" className="mx-1" onClick={() => handleDownloadUserPDF(user)}>
                      {exportLabel}
                    </Button>
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default ApprovedUsersTable;
