import Footer from "../common/pages/footer";
import Header from "../common/pages/header";

const Layout = ({ children }) => (
  <>
    <Header />
    {children}
    <Footer />
  </>
);

export default Layout;
