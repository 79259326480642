import 'bootstrap/dist/css/bootstrap.min.css'
import React, { useState } from 'react'
import { Button, Container, Nav, NavDropdown, Navbar } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { logout } from '../../../auth/authSlice/authSlice'
import rolesConfig from '../../../config/rolesConfig'
import ProfileModal from '../../../modal/ModalProfile'
import './style.css'

function Header() {
  const [showProfileModal, setShowProfileModal] = useState(false)
  const handleProfileClick = () => setShowProfileModal(true)
  const handleCloseProfileModal = () => setShowProfileModal(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleSupportClick = () => {
    navigate('/soporte')
  }

  const handleLogoutClick = () => {
    dispatch(logout())
  }

  const { authUser, hasToken } = useSelector((state) => state.auth)
  const isLoggedIn = hasToken
  const { verified } = authUser || {}

  const renderNavLinks = (role) => {
    return rolesConfig[role]?.map((route, index) => (
      <Nav.Link as={Link} to={route.path} key={index}>
        {route.name}
      </Nav.Link>
    ))
  }

  return (
    <Navbar bg="white" expand="lg" className="shadow-sm navbar-white">
      <Container>
        <Navbar.Brand as={Link} to={'/'}>
          {'Inicio'}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {isLoggedIn ? (
              verified ? (
                <>
                  {renderNavLinks(authUser.userRole)}
                  <Nav.Link as={Link} to="/contact">
                    Contacto
                  </Nav.Link>
                </>
              ) : (
                <Nav.Link as={Link} to="/upload-documents">
                  Subir Documentos
                </Nav.Link>
              )
            ) : (
              <>
                <Nav.Link as={Link} to="/contact">
                  Contacto
                </Nav.Link>
              </>
            )}
          </Nav>
          {isLoggedIn ? (
            <Nav>
              <NavDropdown title={`Hola, ${authUser.personName}`} id="basic-nav-dropdown">
                <NavDropdown.Item onClick={handleProfileClick}>Ver Perfil</NavDropdown.Item>
                <NavDropdown.Item onClick={handleSupportClick}>Soporte o Ayuda</NavDropdown.Item>
                <NavDropdown.Item onClick={handleLogoutClick}>Cerrar Sesión</NavDropdown.Item>
              </NavDropdown>
            </Nav>
          ) : (
            <>
              <Button
                variant="primary"
                style={{
                  backgroundColor: '#333',
                  color: 'white',
                  border: 'none',
                  marginRight: '10px',
                }}
                onClick={() => navigate('/login')}
              >
                Iniciar Sesión
              </Button>
            </>
          )}
        </Navbar.Collapse>
      </Container>
      {authUser && <ProfileModal show={showProfileModal} handleClose={handleCloseProfileModal} />}
    </Navbar>
  )
}

export default Header
