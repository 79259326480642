import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const approveUser = async (idUser, token) => {
    try {
        const response = await axios.post(
            `${API_URL}api/users/approve-user`,
            { id_user: idUser },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error approving user:', error);
        throw error;
    }
};
