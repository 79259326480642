const styles = {
  pdfGrid: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    marginTop: '20px',
  },
};

export default styles;
